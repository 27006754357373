import React, { useState } from "react";
import Camera from "react-html5-camera-photo";

const MyCamera = (props) => {
  const [error, setError] = useState(false);

  if (error) {
    return <></>;
  }

  return (
    <Camera
      idealResolution={{ width: 320, height: 320 }}
      isSilentMode={true}
      isImageMirror={true}
      onTakePhoto={(dataUri) => {
        props.handleTakePhoto(dataUri);
      }}
      onCameraError={() => {
        setError(true);
      }}
    />
  );
};

export default MyCamera;
