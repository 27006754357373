import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import "./../App.css";

const App = () => {
  return (
    <div className="App">
      <section className="app-content">
        <Container>
          <Row className="mb-5">
            <Col>
              <img className="logo" src="images/logo_ASTER.png" alt="" />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <FontAwesomeIcon
                style={{ fontSize: "4rem", color: "green" }}
                icon={faCheckCircle}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Foto cadastrada com sucesso!</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="d-flex justify-content-center"></Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default App;
