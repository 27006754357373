import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const NotFound = () => {
  return (
    <div className="app-content">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center">404</h1>
            <h3 className="text-center">Página não encontrada.</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
