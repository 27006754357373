import React, { useState } from "react";
import ReactModal from 'react-modal';
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

import "./ModalInstructions.css";

const ModalInstructions = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <ReactModal
      isOpen={showModal}
      ariaHideApp={false}
      contentLabel="Instruções"
    >
      <Container className={'text-center'}>
        <Row>
          <Col>
            <img className="rosto-inical" src="./images/modal-instructions/rosto_inicial.png" alt="ROSTO INICIAL" />
          </Col>
        </Row>

        <Row className="m-2">
          <Col>
            <h6>SIGA AS INSTRUÇÕES ABAIXO, PARA CADASTRAR UMA FOTO PERFEITA:</h6>
          </Col>
        </Row>

        <Row className="text-center">
          <Col>
            <ul>
              <li>Mantenha a sua expressão facial natural. Evite sorrisos ou caretas, e mantenha os olhos abertos.</li>
              <li>Não use chapéu, óculos de sol, máscara ou outros acessórios que possam afetar no reconhecimento do seu rosto.</li>
              <li>Não deixe o cabelo cobrir os olhos, orelhas, etc. Maquiagem pesada não é aconselhável.</li>
              <li>Procure tirar a foto em ambiente com o fundo neutro, sem muitos detalhes e com uma boa iluminação.</li>
              <li>Não faça foto coletiva. Deve ter apenas um rosto por foto. Tenha como principal referência uma foto 3X4.</li>
            </ul>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_varios.png" alt="Vários rostos" />
          </Col>
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_chapeu.png" alt="Rosto com chápeu" />
          </Col>
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_longe.png" alt="Rosto de longe" />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_mascara.png" alt="Rosto com máscara" />
          </Col>
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_oculos_escuro.png" alt="Rosto com óculos escuros" />
          </Col>
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_proximo.png" alt="Rosto próximo" />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_perfil.png" alt="Rosto com perfil" />
          </Col>
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_central.png" alt="Rosto central" />
          </Col>
          <Col col={4} col-sm={3}>
            <img className="rostos" src="./images/modal-instructions/rosto_ok.png" alt="Rosto ideal" />
          </Col>
        </Row>
      </Container>

      <button onClick={() => setShowModal(false)} className="btn-basic mt-5">CADASTRAR FOTO</button>
    </ReactModal>
  );
};

export default ModalInstructions;
