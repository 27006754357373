import API from '../plugins/axios';

export const validaHash = (hash) => {
  return new Promise((resolve, reject) => {
    API.get(`/hospedes/${hash}/validar`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateImage = (hash, foto) => {

  const newPhoto = foto.split('base64');

  const data = {
    foto: newPhoto[1]
  }

  return new Promise((resolve, reject) => {
    API.put(`/hospedes/${hash}`, data)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};