import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MyCamera from "./components/MyCamera";
import ModalInstructions from "./components/ModalInstructions";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

import "react-html5-camera-photo/build/css/index.css";
import "./App.css";
import { updateImage, validaHash } from "./services/hospede";

const App = () => {
  const [dataUri, setDataUri] = useState("");
  const [invalidHash, setInvalidHash] = useState(false);
  const [loadingHash, setLoadingHash] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { hash } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const validateHash = async () => {
      setLoadingHash(true);
      try {
        await validaHash(hash);
        setInvalidHash(false);
      } catch (error) {
        setInvalidHash(true);
      }
      setLoadingHash(false);
    };
    validateHash();
  }, [hash]);

  function handleTakePhoto(dataUri) {
    setDataUri(dataUri);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileInput = async (file) => {
    if (file.size > 200000) {
      Swal.fire({
        title: "Erro!",
        text: "O tamanho máximo permitido é 200KB",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      const base64File = await toBase64(file);
      setDataUri(base64File);
    }
  };

  const submitPhoto = async () => {
    setLoadingSubmit(true);
    try {
      await updateImage(hash, dataUri);
      Swal.fire({
        title: "Sucesso!",
        text: "Imagem cadastrada com sucesso",
        icon: "success",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate("/sucesso", { replace: true });
      });
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          title: "Atenção",
          text: error.response.data.message,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }

      if (error.response.status >= 500) {
        Swal.fire({
          title: "Erro!",
          text: "Erro ao cadastrar imagem",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }

    }
    setLoadingSubmit(false);
  };


  if (loadingHash) {
    return (
      <div className="App">
        <section className="app-content">
          <Container>
            <Row className="mb-5">
              <Col>
                <img className="logo" src="images/logo_ASTER.png" alt="" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-center">
                <FontAwesomeIcon
                  style={{ color: "#000", fontSize: "2rem" }}
                  className="fa-spin"
                  icon={faSpinner}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }

  if (!loadingHash && invalidHash) {
    return (
      <div className="App">
        <section className="app-content">
          <Container>
            <Row className="mb-5">
              <Col>
                <img className="logo" src="images/logo_ASTER.png" alt="" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-center">
                <h1>Link inválido!</h1>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }

  if (!loadingHash && !invalidHash) {
    return (
      <div className="App">
        <ModalInstructions />
        <section className="app-content">
          <Container>
            <Row className="mb-5">
              <Col>
                <img className="logo" src="images/logo_ASTER.png" alt="" />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2>Tire uma foto nítida do seu rosto</h2>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-center">
                {dataUri ? (
                  <div>
                    <Row>
                      <Col>
                        <img className="image-preview" src={dataUri} alt="" />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <Button
                          variant="danger"
                          size="md"
                          onClick={() => setDataUri("")}
                        >
                          <FontAwesomeIcon icon={faTrash} /> Excluir foto
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <div className="divCamera">
                      <MyCamera handleTakePhoto={handleTakePhoto} />
                      <div className="cardBorder"></div>
                    </div>
                    <Form.Group controlId="formFile" className="my-3">
                      <Form.Label>
                        Ou então escolha uma foto da galeria
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => handleFileInput(e.target.files[0])}
                      />
                    </Form.Group>
                    <small>Formato: jpg ou png</small>
                    <small>Tamanho máximo: 200KB</small>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <button
                  disabled={dataUri === ""}
                  onClick={submitPhoto}
                  className="btn-basic"
                >
                  {loadingSubmit ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : (
                    "CONTINUAR"
                  )}
                </button>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
};

export default App;
